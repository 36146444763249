export const WORDS = [
  'armor',
  'bleed',
  'viper',
  'leaxa',
  'glyph',
  'knock',
  'curio',
  'taunt',
  'velen',
  'nymue',
  'hodir',
  'skull',
  'wyrms',
  'ghuun',
  'slime',
  'bling',
  'kraul',
  'hyjal',
  'mists',
  'dryad',
  'ahune',
  'flora',
  'vashj',
  'bonus',
  'aegis',
  'guarm',
  'arena',
  'frost',
  'druid',
  'newbs',
  'cycle',
  'gluth',
  'mages',
  'dwarf',
  'tanks',
  'guile',
  'crypt',
  'brann',
  'havoc',
  'ingot',
  'steed',
  'daily',
  'score',
  'cloth',
  'magma',
  'flask',
  'magni',
  'epoch',
  'vigil',
  'dodge',
  'azure',
  'trash',
  'grind',
  'jaina',
  'anima',
  'rares',
  'elder',
  'aqiri',
  'wands',
  'haste',
  'agony',
  'pally',
  'dazar',
  'plate',
  'tirna',
  'count',
  'focus',
  'claws',
  'rylak',
  'drust',
  'bloom',
  'chaos',
  'ducks',
  'argus',
  'blast',
  'titan',
  'ottuk',
  'wrynn',
  'kalec',
  'relic',
  'gulch',
  'heals',
  'party',
  'ashen',
  'coren',
  'drake',
  'malor',
  'vigor',
  'gruul',
  'tidal',
  'souls',
  'staff',
  'gloom',
  'loken',
  'valor',
  'kresh',
  'triad',
  'smite',
  'golem',
  'rival',
  'vials',
  'sword',
  'runed',
  'beast',
  'bombs',
  'kruul',
  'kodos',
  'crane',
  'whelp',
  'elves',
  'dread',
  'uldir',
  'seeds',
  'herod',
  'goren',
  'akama',
  'swing',
  'sewer',
  'guard',
  'chess',
  'level',
  'swarm',
  'yalnu',
  'spire',
  'venom',
  'gnome',
  'macro',
  'spine',
  'patch',
  'jinyu',
  'eonar',
  'honor',
  'helya',
  'crawg',
  'shock',
  'herbs',
  'gamon',
  'satyr',
  'bless',
  'grove',
  'skadi',
  'queue',
  'shard',
  'draka',
  'lunar',
  'gnoll',
  'speed',
  'naaru',
  'wrath',
  'scale',
  'boots',
  'tiers',
  'flame',
  'ghoul',
  'freya',
  'roots',
  'elekk',
  'raids',
  'codex',
  'spear',
  'realm',
  'maiev',
  'faire',
  'hyena',
  'opera',
  'ysera',
  'uldum',
  'grunt',
  'stave',
  'charm',
  'races',
  'ogron',
  'blink',
  'deios',
  'nzoth',
  'troll',
  'feral',
  'ursoc',
  'power',
  'amani',
  'plume',
  'hozen',
  'magus',
  'robes',
  'dream',
  'ember',
  'shade',
  'iskar',
  'phase',
  'kurog',
  'nalak',
  'nexus',
  'baine',
  'chasm',
  'blaze',
  'thorn',
  'chest',
  'ignis',
  'feast',
  'elite',
  'crest',
  'oozes',
  'briar',
  'vault',
  'demon',
  'ruins',
  'bruce',
  'sigil',
  'forge',
  'aldor',
  'light',
  'basin',
  'purge',
  'cloak',
  'snare',
  'badge',
  'group',
  'leech',
  'cthun',
  'earth',
  'mount',
  'shear',
  'kezan',
  'ghost',
  'barov',
  'taloc',
  'halls',
  'epics',
  'storm',
  'fiery',
  'rogue',
  'quest',
  'build',
  'guild',
  'spawn',
  'fauna',
  'blood',
  'beams',
  'yazma',
  'kirin',
  'grong',
  'spark',
  'quack',
  'nerub',
  'aeons',
  'totem',
  'hemet',
  'ogres',
  'human',
  'blade',
  'horde',
  'shirt',
  'crush',
  'rezan',
  'arrow',
  'vines',
  'elune',
  'fjord',
  'curse',
  'magic',
  'clash',
  'uther',
  'spell',
  'igira',
  'parry',
]
